import React, { useState } from "react"
import Layout from "../components/layout"
import { Link, useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import HeroVideo from "../components/heroVideo"
import PortfolioCarousel from "../components/portfolioCarousel"
import OptInForm from "../components/optInForm"
import FAQ from "../components/faq"
import GoogleReviewCarousel from "../components/google-review-carousel"
import * as IndexStyles from "../styles/index.module.scss"

const IndexPage = () => {
  const [faqs, setfaqs] = useState([
    {
      question: "Are you insured?",
      answer:
        "Yes your space is safe with us. We carry full General Liability for your home and Worker’s Compensation for all employees.",
      open: false,
    },
    {
      question: "Do I have to buy the paint?",
      answer:
        "No, that is on us. We use Sherwin Williams products that offer the highest quality for look and long term protection. Our estimates include the labor and the purchasing of the paint.",
      open: false,
    },
    {
      question: "Do I need to be home while my house is painted?",
      answer:
        "Nope! You dont need to be present at the home or space whilte it is painted and we can make any arrangements regarding in-home access prior to starting the project.",
      open: false,
    },
    {
      question: "Will I need to clean up after my paint job is finished?",
      answer:
        "No, we put everything we move back where it was, we remove all of our own waste, scrap and materials. Our goal is to make it look like we were never there.",
      open: false,
    },
    {
      question: "What forms of payment do you accept?",
      answer:
        "We mostly do payments through a secure online credit card processor that we send to your email. Easy peasy! But if you would prefer we also accept Zelle or check.",
      open: false,
    },
  ])

  const toggleFAQ = index => {
    setfaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open
        } else {
          faq.open = false
        }

        return faq
      })
    )
  }

  const data = useStaticQuery(graphql`
    query IndexQuery {
      heroVideo: cloudinaryMedia(public_id: { eq: "pivot-painters-chicago/k-v4" }) {
        secure_url
      }

      calendarIcon: cloudinaryMedia(public_id: { eq: "pivot-painters-chicago/calendar" }) {
        secure_url
      }
      paintBrushIcon: cloudinaryMedia(
        public_id: { eq: "pivot-painters-chicago/icons/paint-brush" }
      ) {
        secure_url
      }
      paletIcon: cloudinaryMedia(public_id: { eq: "pivot-painters-chicago/icons/pantone" }) {
        secure_url
      }
      smileyIcon: cloudinaryMedia(public_id: { eq: "pivot-painters-chicago/icons/happy" }) {
        secure_url
      }

      logo: cloudinaryMedia(public_id: { eq: "pivot-painters-chicago/pivot-painters-logo" }) {
        secure_url
      }
      bmIcon: cloudinaryMedia(public_id: { eq: "pivot-painters-chicago/icons/bm" }) {
        secure_url
      }
      swIcon: cloudinaryMedia(
        public_id: { eq: "pivot-painters-chicago/icons/sherwin-williams" }
      ) {
        secure_url
      }
      googleIcon: cloudinaryMedia(
        public_id: { eq: "pivot-painters-chicago/icons/5-Google-Review-Badge" }
      ) {
        secure_url
      }
      angiIcon: cloudinaryMedia(
        public_id: { eq: "pivot-painters-chicago/icons/toprated-solid-border" }
      ) {
        secure_url
      }

      portfolioImg1: cloudinaryMedia(
        public_id: {
          eq: "pivot-painters-chicago/portfolio/pivot-painters-1433-north-cleveland-ave-chicago-13"
        }
      ) {
        secure_url
      }
      portfolioImg2: cloudinaryMedia(
        public_id: {
          eq: "pivot-painters-chicago/portfolio/pivot-painters-900-north-richmond-street-chicago-18"
        }
      ) {
        secure_url
      }
      portfolioImg3: cloudinaryMedia(
        public_id: {
          eq: "pivot-painters-chicago/pivot-painters-3636-west-dickens-avenue-chicago-23"
        }
      ) {
        secure_url
      }
      portfolioImg4: cloudinaryMedia(
        public_id: {
          eq: "pivot-painters-chicago/pivot-painters-3636-west-dickens-avenue-chicago-22"
        }
      ) {
        secure_url
      }
      portfolioImg5: cloudinaryMedia(
        public_id: {
          eq: "pivot-painters-chicago/pivot-painters-2800-north-talman-avenue-chicago-11"
        }
      ) {
        secure_url
      }
      portfolioImg6: cloudinaryMedia(
        public_id: {
          eq: "pivot-painters-chicago/portfolio/pivot-painters-1107-north-sacramento-chicago-13"
        }
      ) {
        secure_url
      }
      portfolioImg7: cloudinaryMedia(
        public_id: {
          eq: "pivot-painters-chicago/portfolio/pivot-painters-900-north-richmond-street-chicago-9"
        }
      ) {
        secure_url
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Painters Chicago | Top Rated - Pivot Painters Chicago"
        description="Pivot Painters Chicago is a locally owned Chicago Painting company providing residential interior painting and cabinet refinishing. Free Painting Quotes!"
        image={data.logo.secure_url}
      />
      <HeroVideo sourceUrl={data.heroVideo.secure_url}></HeroVideo>
      {/* <section className="introVideo">
          <iframe
            src='https://www.youtube.com/embed/ob6WXRuzWxc'
            title=""
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            loading = 'lazy'
          />
        </section> */}
      <section className={IndexStyles.trustBadges}>
        <img
          src={data.bmIcon.secure_url}
          alt="Pivot Painters Chicago - Benjamin Moore Icon"
        />
        <img
          src={data.swIcon.secure_url}
          alt="Pivot Painters Chicago - Sherwin Williams Icon"
        />
        <img
          src={data.angiIcon.secure_url}
          alt="Pivot Painters Chicago - Angi Leads Icon"
        />
        <img
          src={data.googleIcon.secure_url}
          alt="Pivot Painters Chicago - Google Icon"
        />
      </section>
      <section className={`${IndexStyles.intro} ${IndexStyles.bottomPadding}`}>
        <p
          className={`${IndexStyles.sectionHeader} ${IndexStyles.bottomPadding}`}
        >
          <span className="heavyLato">Experience the best</span> in painting
          services
        </p>
        <div className={IndexStyles.subContainer}>
          <PortfolioCarousel
            images={[
              data.portfolioImg1.secure_url,
              data.portfolioImg2.secure_url,
              data.portfolioImg3.secure_url,
              data.portfolioImg4.secure_url,
              data.portfolioImg5.secure_url,
            ]}
          />
          <div className={IndexStyles.introText}>
            <p>
              Pivot Painters Chicago is the most trusted and reputable name in
              painting services. For over 5 years, our expert Chicago painters
              have helped transform hundreds of homes just like yours, while
              setting the standard for quality, value and customer satisfaction.
              <br />
              <br />
              From North to South Chicago, every project we do is with quality
              in mind. In fact, we guarantee to deliver the best results
              possible for your home using our safe and effective painting
              process.
              <br />
              <br />
              Call{" "}
              <span className="heavyLato underLine">
                <a href="tel:3128549180">‪(312) 854-9180‬</a>
              </span>{" "}
              to connect with a painting expert. We’ll setup a time for an
              estimate and get you on our schedule in minutes!
            </p>
            <Link className={IndexStyles.ctaButton} to="/services">
              Our Services
            </Link>
          </div>
        </div>
      </section>
      <section
        className={`${IndexStyles.fourStepProcess} ${IndexStyles.bottomPadding}`}
      >
        <p className={`${IndexStyles.sectionHeader} ${IndexStyles.noPadding}`}>
          Our <span className="heavyLato">Simple 4-Step Process</span> To Get
          Started
        </p>
        <div className={IndexStyles.steps}>
          <div className={IndexStyles.row}>
            <div className={IndexStyles.step}>
              <p className={IndexStyles.stepName}>1) Set Estimate Date</p>
              <img
                src={data.calendarIcon.secure_url}
                alt="Pivot Painters Chicago - Calendar Icon"
              />
              <p>
                <Link to="/request-a-quote">
                  Set up a convenient time for you{" "}
                </Link>
                , and one of our site managers will meet with you on your time
                to give you a free, on-the-spot, no-obligation estimate for your
                house or project.
              </p>
            </div>
            <div className={IndexStyles.step}>
              <p className={IndexStyles.stepName}>2) Define Your Project</p>
              <img
                src={data.paintBrushIcon.secure_url}
                alt="Pivot Painters Chicago - Paint Brush I@media only screen and (max-width: $break-m){
        con"
              />
              <p>
                Work with smart, competent estimators who wills guide you
                through your options to achieve the perfect look for your home.
                Ask us any questions and we'll be happy to assist!
              </p>
            </div>
          </div>
          <div className={IndexStyles.row}>
            <div className={IndexStyles.step}>
              <p className={IndexStyles.stepName}>3) Pick Your New Colors</p>
              <img
                src={data.paletIcon.secure_url}
                alt="Pivot Painters Chicago - Palet Icon"
              />
              <p>
                Determine the paint colors you want to get that special look you
                are wanting. Need help? Feel free to meet with a Sherwin
                Williams store color consultant.
              </p>
            </div>

            <div className={IndexStyles.step}>
              <p className={IndexStyles.stepName}>4) Transform Your Home</p>
              <img
                src={data.smileyIcon.secure_url}
                alt="Pivot Painters Chicago - Smiley Face Icon"
              />
              <p>
                Sit back and relax while we finish your project. You will have a
                dedicated site-manager should additional questions come up in
                the project.
              </p>
            </div>
          </div>
          <Link className={IndexStyles.ctaButton} to="/request-a-quote">
            Get a Free Quote Today!
          </Link>
        </div>
      </section>
      <section>
        <p className={IndexStyles.sectionHeader}>
          What Past Customers Are Saying
        </p>
        <GoogleReviewCarousel />
      </section>
      <section
        className={`${IndexStyles.bottomPadding} ${IndexStyles.lightBackground}`}
      >
        <p className={`${IndexStyles.sectionHeader} ${IndexStyles.noPadding}`}>
          About Pivot Painters Chicago
        </p>
        <div className={IndexStyles.textContainer}>
          <p>
            Pivot Painters Chicago are top-rated professional Chicago painters
            located in the Logan Square neighborhood of North Chicago. We
            specialize in{" "}
            <Link to="/interior-painting-chicago">
              residential interior painting
            </Link>{" "}
            and{" "}
            <Link to="/cabinet-refinishing-chicago">cabinet refinishing.</Link>{" "}
            We are trusted and loved by many of our Chicago neighbors because of
            the way we communicate everything with them, deliver a great
            painting service experience, and help transform their homes!
          </p>
          <div className={IndexStyles.imageContainer}>
            <img
              src={data.portfolioImg6.secure_url}
              alt="Pivot Painters Chicago | Chicago Interior Painting Project"
            />
          </div>

          <p>
            We always offer <Link to="request-a-quote">Free Quotes</Link> for
            anyone looking to have their home painted and communicate clearly
            with you through the whole process to ensure all your questions are
            answered and that you're always updated with the progress of jobs
            through daily reports. We strive to not only provide you with an
            amazing painting service, but also provide you with a friendly and
            professional experience that will serve as proof to us being the
            best painters in Chicago.
          </p>
        </div>
      </section>
      <section className={IndexStyles.faqs}>
        <p className={`${IndexStyles.sectionHeader} ${IndexStyles.noPadding}`}>
          Frequent Asked Questions
        </p>

        {faqs.map((faq, i) => (
          <FAQ faq={faq} index={i} key={i} toggleFAQ={toggleFAQ} />
        ))}
      </section>

      <section
        className={`${IndexStyles.guarantee} ${IndexStyles.lightBackground} ${IndexStyles.bottomPadding}`}
      >
        <p className={`${IndexStyles.sectionHeader} ${IndexStyles.noPadding}`}>
          <span className="heavyLato">Skip The Stress And Hassle</span>
        </p>
        <div className={IndexStyles.textContainer}>
          <p className={IndexStyles.text}>
            From worries about current damage to your home to anxieties of
            dealing with less-than-honest 'craigslist contractors', we know the
            idea of painting your home can be stressful. Beyond the hustle and
            bustle of each day, you don't deserve to worry about those things as
            well.
          </p>
          <br />
          <p className={IndexStyles.text}>
            That's why we have the Pivot Painters 2-Year,{" "}
            <strong className="highlight">100% Quality Guarantee</strong>.
          </p>
        </div>
        <div className={IndexStyles.warrantyPopup}>
          <p className={IndexStyles.warrantyPopupHeader}>
            PIVOT PAINTER'S 2-YEAR, 100% QUALITY GUARANTEE
          </p>
          <div className={IndexStyles.warrantyPopupText}>
            <p>
              Pivot Painters offers a two-year warranty against defects in the
              workmanship. We'll do the job right the first-time, but should
              your home be among the small percentage of jobs that do peel or
              blister within two years we will stand behind our work. <br></br>
              <br></br>Simply call or email our office and we will send a
              site-manager to evaluate and document the work to be done. We will
              then come out at our own costs and fix the issue in a timely
              manner.
            </p>
          </div>
        </div>
      </section>
      <section className={IndexStyles.topPadding}>
        <p className={`${IndexStyles.sectionHeader} ${IndexStyles.noPadding}`}>
          Professional Chicago Painters
        </p>
        <div className={IndexStyles.textContainer}>
          <p>
            When it comes to having work done in the place you call home, trust
            is everything. Pivot Painters Chicago gets that and lives out that
            core value of ours by only working with individuals who understand
            honesty and openness as an integral part not only in their time at
            work but all the time. We want to provide you with the same quality
            and care we would if we were painting our family's home.
          </p>
          <div className={IndexStyles.imageContainer}>
            <img
              src={data.portfolioImg7.secure_url}
              alt="Pivot Painters Chicago | Humboldt Park Chicago Interior Painting Project"
            />
          </div>
          <p>
            Pivot Painters Chicago has worked with hundreds of our local Chicago
            neighbors transforming their homes through{" "}
            <Link to="/cabinet-refinishing-chicago">cabinet refinishing</Link>{" "}
            and
            <Link to="interior-painting-chicago">interior painting.</Link> We
            work with you all along the way to make sure you are happy with the
            experience and painting by providing constant communication and
            encouraging the people we work with to provide us feedback. Schedule
            a Free Painting Estimate today and see why Pivot Painters are the #1
            Chicago Painters!
          </p>
        </div>
      </section>
      <section className="contact bottomPadding">
        {/*
       <h2>
          Ready To <span className="heavyLato">Transform</span> Your Home?
        </h2>
        <div>
          <a href="/request-a-quote">‪Get Your Quote</a>
          
      </div>
      */}
        <OptInForm></OptInForm>
      </section>
    </Layout>
  )
}

export default IndexPage
