import React, { useState, useEffect } from "react"
import * as HeroStyles from "../styles/heroVideo.module.scss"
import { navigate } from "gatsby"
import Img from "gatsby-image"

const HeroVideo = ({ sourceUrl }) => {
  const videoSrc =
    "https://res.cloudinary.com/dpk99qr30/video/upload/q_auto/v1636998290/pivot-painters-chicago/k-v4.mov"
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })
  useEffect(() => {
    const isClient = typeof window === "object"
    if (isClient)
      setWindowSize({ width: window.innerWidth, height: window.innerHeight })

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const handleResize = () => {
    const isClient = typeof window === "object"
    setWindowSize({
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    })
  }

  return (
    <div className={HeroStyles.container}>
      <div className={HeroStyles.bannerText}>
        <h1>Pivot Painters Chicago</h1>
        <p className={HeroStyles.secondaryHeaderText}>Pivot Your Home</p>
        <HeroBookingForm />
      </div>
      <video
        className={HeroStyles.video}
        alt="Spraying Kitchen Cabinete | Pivot Painters Chicago"
        autoPlay
        muted
        loop
        width="100%"
        height="auto"
      >
        <source src={videoSrc} type="video/mp4"></source>
      </video>
    </div>
  )
}

const HeroBookingForm = () => {
  const [heroState, setHeroState] = useState({
    name: "",
    phone_number: "",
    email: "",
  })

  const handleInputChange = e => {
    const key = e.target.name
    const value = e.target.value
    setHeroState({
      ...heroState,
      [key]: value,
    })
  }

  const submitForm = async e => {
    e.preventDefault()

    if (window && window.localStorage) {
      window.localStorage.setItem(
        "infoForSignIn",
        JSON.stringify({
          userInfo: {
            email: heroState.email,
            name: heroState.name,
            formLastSent: new Date().toString(),
          },
        })
      )
    }

    fetch(`${process.env.GATSBY_ZONUM_API_URL}/lead`, {
      method: "POST",
      body: JSON.stringify({
        ...heroState,
        status: "New Lead",
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ARN:
          " arn:aws:execute-api:us-east-1:074778375650:qgdj8hao2d/*/POST/lead",
        Auth: "NONE",
      },
    })

    navigate("/request-a-quote/", {
      state: {
        name: heroState.name,
        phone_number: heroState.phone_number,
        email: heroState.email,
      },
    })
  }

  return (
    <div className={HeroStyles.heroFormContainer}>
      <form onSubmit={e => submitForm(e)}>
        <div className={HeroStyles.heroFormInnerContainer}>
          <div className={HeroStyles.heroInputs}>
            <input
              className={HeroStyles.input}
              type="text"
              value={heroState.name}
              name="name"
              onChange={handleInputChange}
              placeholder="First Name*"
            />
            <input
              className={HeroStyles.input}
              type="tel"
              value={heroState.phone_number}
              onChange={handleInputChange}
              placeholder="Phone Number*"
              name="phone_number"
            />
            <input
              className={HeroStyles.input}
              type="email"
              value={heroState.email}
              onChange={handleInputChange}
              placeholder="Email*"
              name="email"
            />
          </div>
          <div className={HeroStyles.incentiveContainer}>
            <div>
              <i className="fas fa-thumbs-up"></i>
              <p>2 Year Warranty</p>
            </div>
            <div>
              <i className="fas fa-check"></i>
              <p>Fully Insured</p>
            </div>
            <div>
              <i className="fas fa-star"></i>
              <p>5 Star Rated Service</p>
            </div>
            <div>
              <i className="fas fa-search"></i>
              <p>Background Checked</p>
            </div>
          </div>
          <div className={HeroStyles.submitButtonContainer}>
            <input
              className={HeroStyles.submitButton}
              type="submit"
              value="Book My Quote"
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default HeroVideo
