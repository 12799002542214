import React from "react"
import * as FaqStyles from "../styles/faq.module.scss"

function FAQ({ faq, index, toggleFAQ }) {
  return (
    <div
      className={`${FaqStyles.faq} ${faq.open ? FaqStyles.open : ""}`}
      key={index}
      onClick={() => toggleFAQ(index)}
    >
      <div className={FaqStyles.faqQuestion}>{faq.question}</div>
      <div className={FaqStyles.faqAnswer}>{faq.answer}</div>
    </div>
  )
}

export default FAQ
